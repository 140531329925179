"use strict";
exports.__esModule = true;
/**
 * @class Constants to hold application constants
 */
var Constants = /** @class */ (function () {
    function Constants() {
    }
    // app name
    Constants.APP_NAME = 'login';
    Constants.DEFAULT_LANDING_PAGE = 'https://www.pitneybowes.com';
    Constants.LOGIN_SERVICES_API = '/loginServices';
    Constants.API_VERSION = '/v2';
    Constants.ACCOUNT_DOMAIN = '/account';
    Constants.PRODUCT_DOMAIN = '/products';
    Constants.SESSION_DOMAIN = '/session';
    Constants.CONFIG_DOMAIN = '/config';
    Constants.ORIGIN_DOMAIN = '/origin/validate';
    Constants.ACCESS_TOKEN = 'x-access-token';
    Constants.REFRESH_TOKEN = 'x-refresh-token';
    // API URIs
    Constants.API_PROXY = '';
    Constants.ROOT_URI = '/';
    Constants.LOGIN_URI = '/login';
    Constants.LOGOUT_URI = '/logout';
    Constants.NO_ACCESS_URI = '/no_access';
    Constants.CALLBACK_URI = '/callback';
    Constants.FORGOT_PASSWORD_URI = '/forgotPassword';
    Constants.RESET_PASSWORD_URI = '/resetPassword';
    Constants.VALIDATE_TOKEN_URI = '/token/validate';
    Constants.CLAIM_ACCOUNT_URI = '/claim';
    Constants.RECLAIM_ACCOUNT_URI = '/reclaim';
    Constants.OKTA_USER_URI = '/api/v1/users/me';
    Constants.OKTA_SESSION_URI = '/api/v1/sessions/me';
    Constants.PRODUCT_CATALOG_URI = '';
    Constants.TRANSACTION_UUID_KEY = 'transactionUuid';
    Constants.X_PB_TRANSACTION_ID = 'X-PB-TransactionId';
    Constants.AUTHORIZATION = 'Authorization';
    Constants.PASSWORD_PATTERN = /(?=.*[A-Z])(?=.*[\d!@#$_%^&*-?~()])[A-Za-z\d!@#_$%^&*-?~()]{8,20}/;
    Constants.FORGIVE_ERRORS = ['/info'];
    Constants.DEFAULT_LOCALE = 'en-us';
    Constants.USER_SELECTED_LOCALE = 'user-selected-locale';
    Constants.QUERY_PARAM_LOCALE = 'query-param-locale';
    Constants.BROWSER_LOCALE = 'browser-locale';
    Constants.LOGIN_ROUTE = 'login';
    Constants.LOGOUT_ROUTE = 'logout';
    Constants.ERROR_ROUTE = 'error';
    Constants.FORGOT_PASSWORD_ROUTE = 'forgot-password';
    Constants.RECLAIM_ACCOUNT_ROUTE = 'reclaim-account';
    Constants.FORGOT_PASSWORD_SUCCESS_ROUTE = 'forgot-password/success';
    Constants.RECLAIM_ACCOUNT_SUCCESS_ROUTE = 'reclaim-account/success';
    Constants.LOADING_ROUTE = 'loading';
    Constants.RESET_PASSWORD_ROUTE = 'reset-password';
    Constants.RESET_PASSWORD_SUCCESS_ROUTE = 'reset-password/success';
    Constants.CLAIM_ACCOUNT_ROUTE = 'claim-account';
    Constants.TARGET_RESOURCE_PARAM = 'TargetResource';
    Constants.REDIRECT_PARAM = 'redirect';
    Constants.FROM_URI_PARAM = 'fromURI';
    Constants.TOKEN_PARAM = 'token';
    Constants.PRODUCT_ID_PARAM = 'productId';
    Constants.SPACE_STR = ' ';
    // MONGODB APIS
    Constants.MONGO_URI = "/mongoServices";
    Constants.CHECK_DOMAIN = "/checkDomain";
    Constants.CHECK_CONFIG = "/checkConfig";
    return Constants;
}());
exports.Constants = Constants;
