import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';

import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';

import { Constants } from '../constants';
import { Utils } from '../utils';

/**
 * @class ConfigService service class to get config values
 * @see https://angular.io/api/core/Injectable
 */
@Injectable({ providedIn: 'root' })
export class ConfigService {

    configData;

    /**
     * @constructor for ConfigService
     * @param {HttpClient} http, to call validate token service
     */
    constructor(private http: HttpClient) { }

    /**
     * @function validateToken function to call backend service to get Config params from API
     * @see https://angular.io/guide/observables
     * @return Observable<any>
     */
    refreshConfig(query: string) {
        const endpoint = Utils.getApiUrl() + Constants.LOGIN_SERVICES_API + Constants.API_VERSION +
            Constants.CONFIG_DOMAIN;
        return this.configData = this.http
            .get(endpoint, { params: { q: query } }).map((response) => response);
    }

    getConfig(query: string) {
        if (this.configData) {
            return this.configData;
        }
        return this.refreshConfig(query);
    }

}
