"use strict";
exports.__esModule = true;
var core_1 = require("@ngx-translate/core");
var ngx_cookie_service_1 = require("ngx-cookie-service");
var local_storage_wrapper_1 = require("../local-storage-wrapper");
var constants_1 = require("./../constants");
var utils_1 = require("./../utils");
var config_service_1 = require("./config.service");
/**
 * @class LocaleService service class to determine locale to translate application content
 * @see https://angular.io/api/core/Injectable
 */
var LocaleService = /** @class */ (function () {
    /**
     * @constructor for LocaleService
     * @param {TranslateService} translate to tranlate content into the selected locale
     * @param {ConfigService} configService to check for locale in query param
     */
    function LocaleService(translate, configService, cookieService) {
        var _this = this;
        this.translate = translate;
        this.configService = configService;
        this.cookieService = cookieService;
        translate.setDefaultLang(constants_1.Constants.DEFAULT_LOCALE);
        try {
            if (typeof window.localStorage === 'undefined' || localStorage === null) {
                // support for qtweb
                if (utils_1.Utils.isCookieEnabled() && cookieService) {
                    this.myLocalStorage = new local_storage_wrapper_1.LocalStorageWrapper(this.cookieService);
                }
                else {
                    // no localStorage supported, use default locale
                    this.setLocale(constants_1.Constants.DEFAULT_LOCALE);
                    return;
                }
            }
            else {
                this.myLocalStorage = localStorage;
            }
            // simply return with user selected language if set
            if (this.myLocalStorage.getItem(constants_1.Constants.USER_SELECTED_LOCALE)
                && this.myLocalStorage.getItem(constants_1.Constants.USER_SELECTED_LOCALE) !== 'null') {
                // add supported languages in ngx-translate
                this.translate.addLangs(utils_1.Utils.getLocalCodes());
                // set language only if we support them
                this.useTranslation(utils_1.Utils.getCurrentLocale());
                return;
            }
            // set default language to en-us
            translate.setDefaultLang(constants_1.Constants.DEFAULT_LOCALE);
            configService.getConfig('all').subscribe(function (res) {
                var browserLang = res['browserLanguage'];
                browserLang = browserLang && browserLang.split(',')[0] ? browserLang.split(',')[0].toLowerCase() : null;
                _this.determineLanguage(browserLang);
            }, function (err) {
                _this.determineLanguage(null);
            });
            // determine the language
        }
        catch (e) {
            // no localStorage supported, use default locale
            this.setLocale(constants_1.Constants.DEFAULT_LOCALE);
        }
    }
    /**
     * @private @function determineLanguage
     * @description function to determine locale
     * Precedence 1 = high, 5 = low.
     * Precedence 1: User selected language from Sign-in page drop down
     * Precedence 2: Query parameter language from URL
     * Precedence 3: Browser language. (doesn't work for QTWeb in Login v1 and Login v2)
     * For IE/Edge, Browser language is set through control panel
     * Precedence 4: Defaults to `en-us`
     */
    LocaleService.prototype.determineLanguage = function (browserLang) {
        this.myLocalStorage.removeItem(constants_1.Constants.QUERY_PARAM_LOCALE);
        this.myLocalStorage.removeItem(constants_1.Constants.BROWSER_LOCALE);
        // check if language is set from query param "eg: https://login...com?locale=de"
        var queryParamLang = utils_1.Utils.getParam('locale');
        if (queryParamLang) {
            queryParamLang = this.parseLocaleCode(queryParamLang);
            this.myLocalStorage.setItem(constants_1.Constants.QUERY_PARAM_LOCALE, queryParamLang.toLowerCase());
        }
        else if (browserLang) {
            browserLang = this.parseLocaleCode(browserLang);
            this.myLocalStorage.setItem(constants_1.Constants.BROWSER_LOCALE, browserLang.toLowerCase());
        }
        // add supported languages in ngx-translate
        this.translate.addLangs(utils_1.Utils.getLocalCodes());
        // set language only if we support them
        this.useTranslation(utils_1.Utils.getCurrentLocale());
    };
    /**
     * @function parseLocaleCode
     * @description function to set parse localeCode if it contains '-' or '_'
     * @param {string} localeCode
     */
    LocaleService.prototype.parseLocaleCode = function (localeCode) {
        localeCode = localeCode.replace('_', '-');
        if (localeCode.indexOf('-') < 0) {
            return localeCode;
        }
        var localeSplit = localeCode.split('-');
        if (!localeSplit[0] || !localeSplit[1]) {
            return localeCode;
        }
        else if (localeSplit[0] === localeSplit[1].toLocaleLowerCase()) {
            localeCode = localeSplit[0];
        }
        else {
            localeCode = localeSplit[0] + '-' + localeSplit[1].toLocaleLowerCase();
        }
        return localeCode;
    };
    /**
     * @function setLocale
     * @description function to set locale and use the translation with that locale
     * @param {string} locale
     */
    LocaleService.prototype.setLocale = function (locale) {
        // set in local storage so that the selected language is preserved for future login's
        locale = locale ? locale.toLowerCase() : null;
        try {
            this.myLocalStorage.setItem(constants_1.Constants.USER_SELECTED_LOCALE, locale);
        }
        catch (e) {
            // no localStorage supported
        }
        this.useTranslation(locale);
    };
    /**
     * @private @function useTranslation
     * @description function that sets locale in translate object
     * @param {string} locale
     */
    LocaleService.prototype.useTranslation = function (locale) {
        if (this.translate.getLangs().indexOf(locale) === -1) {
            // set error logger as language not supported
            locale = constants_1.Constants.DEFAULT_LOCALE;
        }
        this.translate.use(locale);
    };
    return LocaleService;
}());
exports.LocaleService = LocaleService;
