import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';

import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs/Observable';

import { Constants } from '../shared/constants';
import { Utils } from '../shared/utils';

/**
 * @class ValidateTokenService service class to validate token
 * @see https://angular.io/api/core/Injectable
 */
@Injectable()
export class ValidateTokenService {

  token: string;

  /**
   * @constructor for ValidateTokenService
   * @param {HttpClient} http, to call validate token service
   * @param {ActivatedRoute} activatedRoute to get query parameter (token) from URL
   */
  constructor(private http: HttpClient, private activatedRoute: ActivatedRoute) {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.token = params['token'];
    });
  }

  /**
   * @function validateToken function to call backend service to validate the token
   * @see https://angular.io/guide/observables
   * @return Observable<any>
   */
  validateToken(): Observable<any> {
    const endpoint = Utils.getApiUrl() + Constants.LOGIN_SERVICES_API + Constants.API_VERSION +
      Constants.VALIDATE_TOKEN_URI;
    return this.http
      .post(endpoint, { token: this.token }).map((response: HttpResponse<any>) => response);
  }

}
