"use strict";
exports.__esModule = true;
require("rxjs/add/operator/catch");
require("rxjs/add/operator/map");
var http_1 = require("@angular/common/http");
var constants_1 = require("../constants");
var utils_1 = require("../utils");
/**
 * @class SessionService service class to read cookies and session details from login-api
 * @see https://angular.io/api/core/Injectable
 */
var SessionService = /** @class */ (function () {
    /**
     * @constructor for SessionService
     * @param {HttpClient} http, to call session service
     */
    function SessionService(http) {
        this.http = http;
    }
    /**
     * @function getSessionDetails function to Call backend service to get cookies and session details
     * @see https://angular.io/guide/observables
     * @see https://www.typescriptlang.org/docs/handbook/basic-types.html
     * @return Observable<any> | never
     */
    SessionService.prototype.getSessionDetails = function () {
        var endpoint = utils_1.Utils.getApiUrl() +
            constants_1.Constants.LOGIN_SERVICES_API +
            constants_1.Constants.API_VERSION +
            constants_1.Constants.SESSION_DOMAIN;
        return this.http
            .post(endpoint, {})
            .map(function (response) { return response; });
    };
    return SessionService;
}());
exports.SessionService = SessionService;
