"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
exports.__esModule = true;
var styles = ["footer[_ngcontent-%COMP%]   .container[_ngcontent-%COMP%], footer[_ngcontent-%COMP%]   .container-fluid[_ngcontent-%COMP%]{z-index:0}"];
exports.styles = styles;
