import { NgModule } from '@angular/core';
import { Router, RouterModule, Routes } from '@angular/router';

import { LogoutComponent } from './logout/logout.component';
import { Constants } from './shared/constants';
import { FooterComponent } from './shared/footer/footer.component';
import { TrustGuard } from './shared/services/trust-guard.service';
import { SplashScreenComponent } from './shared/splash-screen/splash-screen.component';

/**
 * Define list of routes used in theLOGIN_ROUTE
 * @return {Routes} navigation route
 */
const LOGIN_ROUTE = '/' + Constants.LOGIN_ROUTE + '?' + Constants.PRODUCT_ID_PARAM + '=' + Constants.APP_NAME
  + '&' + Constants.TARGET_RESOURCE_PARAM + '=' + Constants.DEFAULT_LANDING_PAGE;
const routes: Routes = [
  // not using constants for routes as compodoc is not supported with global variables
  { path: '', redirectTo: LOGIN_ROUTE, pathMatch: 'full' },

  { path: 'loading', component: SplashScreenComponent },
  { path: 'login', loadChildren: './login/login.module#LoginModule', canActivate: [TrustGuard] },
  { path: 'logout', component: LogoutComponent, canActivate: [TrustGuard] },

  { path: 'forgot-password', loadChildren: './forgot-password/forgot-password.module#ForgotPasswordModule', canActivate: [TrustGuard] },
  { path: 'forgot-password/success', loadChildren: './forgot-password/success/forgot-password-success.module#ForgotPasswordSuccessModule' },

  { path: 'reset-password', loadChildren: './reset-password/reset-password.module#ResetPasswordModule', canActivate: [TrustGuard] },
  { path: 'reset-password/success', loadChildren: './reset-password/success/reset-password-success.module#ResetPasswordSuccessModule' },

  { path: 'claim-account', loadChildren: './claim-account/claim-account.module#ClaimAccountModule', canActivate: [TrustGuard] },
  { path: 'reclaim-account', loadChildren: './reclaim-account/reclaim-account.module#ReclaimAccountModule', canActivate: [TrustGuard] },
  { path: 'reclaim-account/success', loadChildren: './reclaim-account/success/reclaim-account-success.module#ReclaimAccountSuccessModule' },

  { path: 'error', loadChildren: './shared/error/error.module#ErrorModule' },

  { path: 'footer', component: FooterComponent },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})

/**
 * @class AppRoutingModule Routing module class to route to different URLs
 * @see https://angular.io/guide/router
 */
export class AppRoutingModule {

  /**
   * @constructor for AppRoutingModule
   * @param {Router} router navigate to error page
   */
  constructor(private router: Router) { }

}
