<!--add new class for qtweb-->
<footer class="py-3 qtweb-footer">
  <div class="container-fluid">
    <div class="row align-items-center">
      <div class="col-xs-12 col-md-8 col-lg-6">
        <div class="d-flex flex-column flex-sm-row justify-content-center justify-content-sm-start align-items-sm-center">
          <a class="footer-logo d-block d-sm-inline-block mr-sm-2" href="http://www.pb.com"></a>
          <span class="d-block d-sm-inline-block text-center text-sm-left pt-1 pb-3 p-sm-0"> &copy; {{currentYear}} Pitney Bowes Inc.
            <span [translate]="'GLOBAL.FOOTER.PB'">All rights reserved.</span>
          </span>
        </div>
      </div>

      <div class="col-xs-12 col-md-4 col-lg-6">
        <div class="d-flex justify-content-center justify-content-sm-end align-items-sm-center">
          <a class="d-block mx-3" href="http://www.pitneybowes.com/us/legal/privacy-statement.html" target="_blank" [translate]="'GLOBAL.PRIVACY'">Privacy</a>
          <a class="d-block" href="http://www.pitneybowes.com/us/legal/terms-of-use.html" target="_blank" [translate]="'GLOBAL.TERMS_AND_CONDITIONS'">Terms of use</a>
        </div>
      </div>
    </div>
  </div>
</footer>