<!-- added -webkit-box-orient for the sake of qtweb support -->
<div class="loading-screen" style="-webkit-box-orient: vertical;">
  <div class="loading-screen-unit">
    <div class="loading-screen-logo"></div>
    <div class="loading-screen-spinner text-white">
      <svg width="16" height="16">
        <path fill="#fff"
          d="M8 16a7.928 7.928 0 0 1-3.428-.77l.857-1.807A6.006 6.006 0 0 0 14 8c0-3.309-2.691-6-6-6a6.006 6.006 0 0 0-5.422 8.572l-1.806.859A7.929 7.929 0 0 1 0 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
      </svg> Logging out...
    </div>
  </div>
</div>