"use strict";
exports.__esModule = true;
var i0 = require("@angular/core");
var ProfileService = /** @class */ (function () {
    function ProfileService() {
    }
    ProfileService.ngInjectableDef = i0.defineInjectable({ factory: function ProfileService_Factory() { return new ProfileService(); }, token: ProfileService, providedIn: "root" });
    return ProfileService;
}());
exports.ProfileService = ProfileService;
