import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { Utils } from '../utils';
import { Constants } from './../constants';
import { SplashScreenService } from './splash-screen.service';

export enum Theme {
  classic = 'classic',
  twilight = 'twilight',
  sunset = 'sunset',
  neutral = 'neutral',
  ocean = 'ocean',
}

@Component({
  selector: 'app-splash-screen',
  templateUrl: './splash-screen.component.html',
  styleUrls: ['./splash-screen.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SplashScreenComponent implements OnInit {

  dataLoaded: boolean;
  theme;
  interstitial = false;

  constructor(private splashScreenService: SplashScreenService) {

    const pathname = window.location.pathname;
    if (pathname === Constants.ROOT_URI + Constants.LOADING_ROUTE) {
      this.interstitial = true;
      document.body.setAttribute('theme', 'none');
    } else {
      // change the theme if URL has a theme parameter
      this.theme = Utils.getParam('theme') || 'classic';
    }

  }

  ngOnInit() {
    this.splashScreenService.loaderObserver$.subscribe((dataLoaded) => this.dataLoaded = dataLoaded);
  }

}
