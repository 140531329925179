import { Component, HostBinding, OnInit } from '@angular/core';

import { Utils } from '../utils';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  @HostBinding('class.site-footer') true;

  currentYear = (new Date()).getFullYear();

  constructor() { }

  ngOnInit(): void {
    // support for qtweb
    Utils.setUnusualBrowserSettings();
  }

}
