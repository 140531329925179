"use strict";
exports.__esModule = true;
var core_1 = require("@angular/core");
var utils_1 = require("../utils");
var constants_1 = require("./../constants");
var splash_screen_service_1 = require("./splash-screen.service");
var Theme;
(function (Theme) {
    Theme["classic"] = "classic";
    Theme["twilight"] = "twilight";
    Theme["sunset"] = "sunset";
    Theme["neutral"] = "neutral";
    Theme["ocean"] = "ocean";
})(Theme = exports.Theme || (exports.Theme = {}));
var SplashScreenComponent = /** @class */ (function () {
    function SplashScreenComponent(splashScreenService) {
        this.splashScreenService = splashScreenService;
        this.interstitial = false;
        var pathname = window.location.pathname;
        if (pathname === constants_1.Constants.ROOT_URI + constants_1.Constants.LOADING_ROUTE) {
            this.interstitial = true;
            document.body.setAttribute('theme', 'none');
        }
        else {
            // change the theme if URL has a theme parameter
            this.theme = utils_1.Utils.getParam('theme') || 'classic';
        }
    }
    SplashScreenComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.splashScreenService.loaderObserver$.subscribe(function (dataLoaded) { return _this.dataLoaded = dataLoaded; });
    };
    return SplashScreenComponent;
}());
exports.SplashScreenComponent = SplashScreenComponent;
