"use strict";
exports.__esModule = true;
var tslib_1 = require("tslib");
var router_1 = require("@angular/router");
var BehaviorSubject_1 = require("rxjs/BehaviorSubject");
var of_1 = require("rxjs/observable/of");
var constants_1 = require("../constants");
var utils_1 = require("../utils");
var origin_validator_service_1 = require("./origin-validator.service");
var TrustGuard = /** @class */ (function () {
    function TrustGuard(router, originValidatorService) {
        this.router = router;
        this.originValidatorService = originValidatorService;
        this.originValidated = new BehaviorSubject_1.BehaviorSubject(false);
    }
    TrustGuard.prototype.canActivate = function () {
        var _this = this;
        // validates if required params are available in the URL
        return this.validateParams().then(function () {
            // validates the fromURI or TargetResource hostnames with the productId
            return _this.validateOrigin().then(function (res) {
                _this.originValidated.next(true);
                return Promise.resolve(res);
            });
        });
    };
    TrustGuard.prototype.validateOrigin = function () {
        var _this = this;
        var productId = utils_1.Utils.getParam(constants_1.Constants.PRODUCT_ID_PARAM);
        return this.originValidatorService.validateOrigin(productId).map(function (r) {
            _this.originValidated.next(true);
            if (r['status'] || r['target']) {
                if (r.status && !r.target) {
                    return true;
                }
                else if (r.status && r['target']) {
                    return _this.redirectForValidPage(r);
                }
            }
            else {
                _this.redirectToErrorPage();
                return false;
            }
        })["catch"](function (e) {
            // Validation failed with invalid redirect for logout URI
            if (window.location.pathname === constants_1.Constants.ROOT_URI + constants_1.Constants.LOGOUT_ROUTE) {
                _this.redirectToDefaultLogoutPage();
            }
            else {
                // validation failed for TargetResources/fromURI
                _this.originValidated.next(true);
                _this.redirectToErrorPage();
                return of_1.of(false);
            }
        }).toPromise();
    };
    TrustGuard.prototype.redirectForValidPage = function (r) {
        switch (window.location.pathname) {
            case constants_1.Constants.ROOT_URI + constants_1.Constants.LOGIN_ROUTE:
                this.redirectToInterstitialPage();
                window.location.href = r.target;
                return false;
            case constants_1.Constants.ROOT_URI + constants_1.Constants.CLAIM_ACCOUNT_ROUTE:
            case constants_1.Constants.ROOT_URI + constants_1.Constants.RESET_PASSWORD_ROUTE:
            case constants_1.Constants.ROOT_URI + constants_1.Constants.RECLAIM_ACCOUNT_ROUTE:
                utils_1.Utils.setParam(constants_1.Constants.TARGET_RESOURCE_PARAM, r.target);
                return true;
            default:
                return r.status;
        }
    };
    TrustGuard.prototype.redirectToErrorPage = function () {
        var id = utils_1.Utils.getTransactionId();
        var url = '/' + constants_1.Constants.ERROR_ROUTE + '?errorCode=403&id=' + id;
        this.router.navigateByUrl(url);
    };
    TrustGuard.prototype.redirectToDefaultLogoutPage = function () {
        var url = '/' + constants_1.Constants.LOGOUT_ROUTE + '?redirect=' + constants_1.Constants.DEFAULT_LANDING_PAGE;
        window.location.href = window.location.origin + url;
    };
    TrustGuard.prototype.redirectToInterstitialPage = function () {
        var url = '/' + constants_1.Constants.LOADING_ROUTE;
        this.router.navigateByUrl(url);
    };
    /**
     * @private @function validateParams function to validate the query params and
     * set default params if appropriate parameters are not available
     * @return void
     */
    TrustGuard.prototype.validateParams = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var pathname;
            return tslib_1.__generator(this, function (_a) {
                pathname = window.location.pathname;
                if (pathname === constants_1.Constants.ROOT_URI + constants_1.Constants.ERROR_ROUTE
                    || pathname === constants_1.Constants.ROOT_URI + constants_1.Constants.LOADING_ROUTE) {
                    return [2 /*return*/];
                }
                // when productId param is not available
                if (utils_1.Utils.getParam(constants_1.Constants.PRODUCT_ID_PARAM) === null
                    && pathname !== constants_1.Constants.ROOT_URI + constants_1.Constants.LOGOUT_ROUTE) {
                    this.redirectToErrorPage();
                }
                return [2 /*return*/];
            });
        });
    };
    return TrustGuard;
}());
exports.TrustGuard = TrustGuard;
