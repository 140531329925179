"use strict";
exports.__esModule = true;
require("url-search-params-polyfill");
var http_1 = require("@angular/common/http");
var http_loader_1 = require("@ngx-translate/http-loader");
/**
 * @function HttpLoaderFactory
 * AoT requires an exported function for factories
 * @param {HttpClient} http to load i18n internationalization files
 */
function HttpLoaderFactory(http) {
    return new http_loader_1.TranslateHttpLoader(http);
}
exports.HttpLoaderFactory = HttpLoaderFactory;
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
exports.AppModule = AppModule;
