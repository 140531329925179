"use strict";
exports.__esModule = true;
require("url-search-params-polyfill");
var moment = require("moment-timezone");
var rxjs_1 = require("rxjs");
var scriptjs_1 = require("scriptjs");
var constants_1 = require("./constants");
var locale_constants_1 = require("./locale-constants");
/**
 * @class Utils Utility class for SaaS Login UI
 */
var Utils = /** @class */ (function () {
    function Utils(cookieService) {
        this.cookieService = cookieService;
    }
    /**
     * @public @static @function isEmpty function to check if an Object/String/Array is empty
     * @param value accepts Object/String/Array
     * @return boolean
     */
    Utils.isEmpty = function (value) {
        if (value === undefined || value === null || value === 'undefined' || value.length === 0) {
            return true;
        }
        if (typeof value === 'string' && value.trim().length === 0) {
            return true;
        }
        if (typeof value === 'object') {
            return this.isEmptyObject(value);
        }
        return false;
    };
    /**
     * @public @static @function getCurrentESTDateTimeInISOFormat
     * function to get current EST time using moment.js library
     * @return EST Timestamp in ISO Format
     */
    Utils.getCurrentESTDateTimeInISOFormat = function () {
        return moment.tz(new Date(), 'America/New_York');
    };
    /**
     * @private @static @function isEmptyObject function to check if an object is empty
     * @param obj accepts object
     * @return boolean
     */
    Utils.isEmptyObject = function (obj) {
        for (var key in obj) {
            if (obj.hasOwnProperty(key)) {
                return false;
            }
        }
        return true;
    };
    /**
     * @static @function getApiUrl function to get API URL
     * @return string | undefined, returns API URL
     */
    Utils.getApiUrl = function () {
        var host = window.location.host.split('.');
        if (window
            && 'location' in window
            && 'protocol' in window.location
            && 'host' in window.location) {
            switch (host[0]) {
                case 'localhost:4200':
                    return '';
                    break;
                case 'login2-pre':
                    var preURL = 'https://' + window.location.host.replace('login2-pre', 'login2-api-pre');
                    preURL = preURL.replace('pitneycloud.com', 'pitneybowes.com');
                    return preURL;
                    break;
                case 'login2':
                    return 'https://' + window.location.host.replace('login2', 'login2-api');
                    break;
                default:
                    return 'https://' + window.location.host.replace('login2', 'login2-api');
            }
        }
        return undefined;
    };
    /**
     * @static @function getParam function
     * @param param to get query parameter from URL
     * @return string | null, returns query param value if available or null if not available
     */
    Utils.getParam = function (param) {
        // route to error page if required params are not available in the URL
        if (window.location.search) {
            var search = new URLSearchParams(window.location.search);
            var value = search.get(param);
            return value && value !== 'null' && value !== 'undefined' ? value : null;
        }
        return null;
    };
    /**
     * @static @function setParam function
     * @param {string} param to set query parameter in URL
     * @param {string} value to set value to the query param
     * @return {string} newly added param
     */
    Utils.setParam = function (param, value) {
        // route to error page if required params are not available in the URL
        if (this.getParam(param) === null) {
            var search = new URLSearchParams(window.location.search);
            search.append(param, value);
            return (window.location.href.indexOf('?') <= -1 ? '?' : '&') + param + '=' + value;
        }
    };
    /**
     * @static @function getOktaUrl function
     * @param param to get query parameter from URL
     * @return string | null, returns query param value if available or null if not available
     */
    Utils.replaceOktaUrl = function (param, url) {
        if (url) {
            return url.replace(param['targetDomain'], param['newTargetDomain']);
        }
        return null;
    };
    /**
     * @static @function getCurrentLocale function
     * @param {cookieService} ngx-cookie-service
     * @return string | null, returns locale based on the precedence
     */
    Utils.getCurrentLocale = function () {
        try {
            if (typeof window.localStorage === 'undefined' || localStorage === null) {
                // qtweb support
                var locale = Utils.getCurrentLocaleFaster(constants_1.Constants.USER_SELECTED_LOCALE)
                    || Utils.getCurrentLocaleFaster(constants_1.Constants.QUERY_PARAM_LOCALE)
                    || Utils.getCurrentLocaleFaster(constants_1.Constants.BROWSER_LOCALE)
                    || constants_1.Constants.DEFAULT_LOCALE;
                return locale ? locale.replace(/\"/gi, '') : null;
            }
            else {
                return localStorage.getItem(constants_1.Constants.USER_SELECTED_LOCALE)
                    || localStorage.getItem(constants_1.Constants.QUERY_PARAM_LOCALE)
                    || localStorage.getItem(constants_1.Constants.BROWSER_LOCALE)
                    || constants_1.Constants.DEFAULT_LOCALE;
            }
        }
        catch (e) {
            // no localStorage supported, use default locale
            return constants_1.Constants.DEFAULT_LOCALE;
        }
    };
    /**
     * @static @function getCurrentLocaleFaster function
     * Introduced as part of QTWeb support
     * This is needed so that we don't worry about returning promise object from CookieService
     * @param {cookieService} ngx-cookie-service
     * @return string | null, returns locale based on the precedence
     */
    Utils.getCurrentLocaleFaster = function (cname) {
        var name = cname + '=';
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        var locale = null;
        ca.forEach(function (c) {
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                locale = c.substring(name.length, c.length);
            }
        });
        return locale;
    };
    /**
     * @static @function getLocalCodes function
     * @return Array of locale codes
     */
    Utils.getLocalCodes = function () {
        var localeCodes = [];
        locale_constants_1.LocaleConstants.LOCALES['locales'].forEach(function (element, index) {
            localeCodes.push(element['code']);
        });
        return localeCodes;
    };
    /**
     * @static @function getErrorCodes function to return error codes
     * @param err
     * @return errorCode or error status code
     */
    Utils.getErrorCodes = function (err) {
        return err['errorCode'] || (err && err['error'] && err['error']['status']
            ? err['error']['status'] : '0');
    };
    /**
     * @function setErrors function to set errors
     * @param err
     * @return errorCode with error message
     */
    Utils.getErrors = function (err) {
        var error = { errorCode: '-1', error: '' };
        error['errorCode'] = err['errorCode'] || (err && err['error'] && err['error']['status']
            ? err['error']['status'] : 0);
        error['message'] = err && err['message'] ? err['message'] : err;
        return error;
    };
    /**
     * @function isCookieEnabled function to check if cookies are enabled or not
     * @return cookiesEnabled boolean
     */
    Utils.isCookieEnabled = function () {
        try {
            document.cookie = 'loginCookie=1';
            var cookiesEnabled = document.cookie.indexOf('loginCookie=') !== -1;
            document.cookie = 'loginCookie=1; expires=Thu, 01-Jan-1970 00:00:01 GMT';
            return cookiesEnabled;
        }
        catch (e) {
            return false;
        }
    };
    /**
     * @function convertQueryStringToObject function to convert URL query strings to object
     * @return object
     */
    Utils.convertQueryStringToObject = function () {
        var pairs = location.search.slice(1).split('&');
        var result = {};
        pairs.forEach(function (pair) {
            var p = pair.split('=');
            result[p[0]] = decodeURIComponent(p[1] || '');
        });
        return JSON.parse(JSON.stringify(result));
    };
    /**
     * @function removeUrlParams function to remove query param from URL without refreshing the page
     * @return void
     */
    Utils.removeUrlParams = function (params) {
        var search = new URLSearchParams(window.location.search);
        params.forEach(function (param) {
            if (search.has(param)) {
                search["delete"](param);
            }
        });
        return window.location.origin + window.location.pathname + '?' + search.toString();
    };
    /**
     * loadDTMScripts function to load DTM library
     */
    Utils.loadDTMScripts = function () {
        window['DDO'] = {};
        scriptjs_1.get(Utils.getLaunchLibrary(), function () {
            Utils.loadLaunchProducts();
        });
    };
    Utils.getLaunchLibrary = function () {
        if (location.hostname === 'login2.pitneybowes.com') {
            return "//assets.adobedtm.com/485c68b96f14/a36bed630b90/launch-ENb232a11a8a18420fb9fdd0e8c2919712.min.js";
        }
        else {
            return "//assets.adobedtm.com/launch-EN8f7ec7286ce24e31a4a4b7e71a0e0b69-staging.min.js";
        }
    };
    Utils.loadLaunchProducts = function () {
        var product = Utils.getParam('productId');
        // set to lowercase productId as we applications set productIDs in uppercase/camelcase
        if (product) {
            product = product.toLowerCase();
        }
        var pathname = location.pathname;
        if (typeof window['_satellite'] === 'undefined') {
            return;
        }
        else if (product && pathname) {
            Utils.setDDOSatellite(product, pathname);
        }
    };
    /**
     * this functions get the X-PB-Transaction-Id for debugging purpose, used in trust guard service
     */
    Utils.getTransactionId = function () {
        if (typeof window.localStorage === 'undefined' || localStorage === null) {
            return '';
        }
        else {
            return localStorage.getItem('X-PB-Transaction-Id');
        }
    };
    /**
     * this functions sets the X-PB-Transaction-Id for debugging purpose
     * @param transactionId
     */
    Utils.setTransactionId = function (transactionId) {
        if (typeof window.localStorage === 'undefined' || localStorage === null) {
            return;
        }
        else {
            localStorage.setItem('X-PB-Transaction-Id', transactionId);
        }
    };
    Utils.setDDOSatellite = function (ddoProduct, pathname) {
        var deviceType = (/Mobi|Android/i.test(navigator.userAgent)) ? 'mobile' : 'desktop';
        // hard coding to US and USD for now, Saila will come back with solution
        window['DDO'].getBootstrap = 'US';
        window['DDO'].currencyCode = 'USD';
        window['DDO'].flowPath = ddoProduct + '||' + pathname;
        window['DDO'].campaign = window['_satellite'].getVar('campaign_cid');
        window['DDO'].globalData = {
            globalHier: 'pb| ' + ddoProduct + ' | ' + pathname,
        };
        window['DDO'].pageData = {
            pageHier: ddoProduct + '|' + pathname,
            pageName: 'ge|' + ddoProduct + '|' + pathname,
            pageRef: document.referrer,
            pageSiteSection: 'myaccount',
            pageType: 'account/' + pathname,
            pageURL: location.href,
        };
        window['DDO'].siteData = {
            siteCountry: '',
            siteDomain: document.domain,
            siteFormat: deviceType,
            siteLanguage: navigator.language,
            siteRegion: '',
        };
        window['DDO'].userData = {
            userID: '',
            oktaId: '',
            userNewReg: '',
            userStatus: 'Not Logged In',
            userType: '',
        };
    };
    Utils.unusualBrowser = function () {
        // qtweb support
        var agent = navigator && navigator.userAgent
            && navigator.userAgent.toLowerCase();
        var unusualBrowsers = ['qtweb', 'pbshell', 'demobrowser', 'qttestbrowser', 'qtemulator'];
        return unusualBrowsers.find(function (e) {
            return agent.indexOf(e) >= 0;
        });
    };
    Utils.setUnusualBrowserSettings = function () {
        /** support for QTWeb */
        if (Utils.unusualBrowser()) {
            var signInWrapper = document.getElementsByClassName('signin-wrapper')[0];
            signInWrapper && signInWrapper.style ? signInWrapper.style.margin = '40px auto !important' : rxjs_1.noop();
            var footerElement = document.getElementsByClassName('qtweb-footer')[0];
            if (footerElement && !footerElement.classList.contains('fixed-bottom')) {
                footerElement.classList.add('fixed-bottom');
            }
            return true;
        }
        else {
            return false;
        }
    };
    return Utils;
}());
exports.Utils = Utils;
