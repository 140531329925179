import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  profileBlob: any; // Define the profileBlob property

  constructor() { }
}
