import { Observable } from 'rxjs/Observable';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Constants } from '../constants';
import { Utils } from '../utils';

@Injectable()
export class OriginValidatorService {

  /**
   * @constructor for OriginValidatorService
   * @param {HttpClient} http, to call origin validator service
   */
  constructor(private http: HttpClient) { }

  /**
   * @function validateOrigin function to Call backend service to validate target resources
   * @see https://angular.io/guide/observables
   * @return Observable<any> | never
   */

  validateOrigin(productId: string): Observable<any> {
    let targetResource = null;
    let logoutRedirect = false;
    if (window.location.pathname === Constants.ROOT_URI + Constants.LOGOUT_ROUTE) {
      if (!productId) {
        productId = 'login';
      }
      targetResource = Utils.getParam('redirect') || Constants.DEFAULT_LANDING_PAGE;
      logoutRedirect = true;
    } else {
      targetResource = Utils.getParam(Constants.TARGET_RESOURCE_PARAM) || Utils.getParam(Constants.FROM_URI_PARAM);
    }
    const endpoint = Utils.getApiUrl() + Constants.LOGIN_SERVICES_API + Constants.API_VERSION +
      Constants.ORIGIN_DOMAIN + Constants.ROOT_URI;
    return this.http
      .post(endpoint, { targetResource, logoutRedirect, productId }).map((response) => response);
  }

}
