"use strict";
exports.__esModule = true;
var core_1 = require("@angular/core");
var operators_1 = require("rxjs/operators");
var constants_1 = require("./shared/constants");
var locale_service_1 = require("./shared/services/locale.service");
var trust_guard_service_1 = require("./shared/services/trust-guard.service");
var splash_screen_service_1 = require("./shared/splash-screen/splash-screen.service");
var utils_1 = require("./shared/utils");
var Theme;
(function (Theme) {
    Theme["classic"] = "classic";
    Theme["twilight"] = "twilight";
    Theme["sunset"] = "sunset";
    Theme["neutral"] = "neutral";
    Theme["ocean"] = "ocean";
    Theme["shipaccel"] = "shipaccel";
})(Theme = exports.Theme || (exports.Theme = {}));
var AppComponent = /** @class */ (function () {
    /**
     * @constructor for AppComponent
     * @param {LocaleService} localeService, to determine the locale to use in the application
     */
    function AppComponent(changeDetectorRef, localeService, splashScreenService, trustGuard) {
        this.changeDetectorRef = changeDetectorRef;
        this.localeService = localeService;
        this.splashScreenService = splashScreenService;
        this.trustGuard = trustGuard;
        this.title = 'SaaS Login UI';
        this.dataLoaded = false;
        /** support for QTWeb */
        var pathname = window.location.pathname;
        if (utils_1.Utils.unusualBrowser() && pathname !== constants_1.Constants.ROOT_URI + constants_1.Constants.LOGOUT_ROUTE
            && pathname !== constants_1.Constants.ROOT_URI + constants_1.Constants.ERROR_ROUTE) {
            // (qtweb) not yet supported to handle various themes, for now we default to classic theme styles
            document.body.setAttribute('style', 'background-image: -webkit-linear-gradient(left, #3e53a4 0%, #cf0989 100%)');
            return;
        }
        // chaget the theme if URL has a theme parameter
        var theme = utils_1.Utils.getParam('theme');
        if (pathname === constants_1.Constants.ROOT_URI + constants_1.Constants.LOADING_ROUTE) {
            document.body.setAttribute('theme', 'none');
        }
        else if (theme in Theme) {
            document.body.setAttribute('theme', theme ? theme : 'classic');
        }
    }
    AppComponent.prototype.ngOnInit = function () {
        this.originValidated$ = this.trustGuard.originValidated;
    };
    AppComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        if (window.location.pathname !== constants_1.Constants.ROOT_URI + constants_1.Constants.LOGOUT_ROUTE) {
            this.splashScreenService.loaderObserver$.pipe((operators_1.tap(function (dataLoaded) {
                if (dataLoaded !== _this.dataLoaded) {
                    _this.dataLoaded = dataLoaded;
                    _this.changeDetectorRef.detectChanges();
                }
            }))).subscribe();
        }
    };
    return AppComponent;
}());
exports.AppComponent = AppComponent;
