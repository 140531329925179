import 'url-search-params-polyfill';

import { Observable } from 'rxjs/Observable';

import { Component, HostBinding, OnInit } from '@angular/core';

import { Constants } from '../shared/constants';
import { ConfigService } from '../shared/services/config.service';
import { Utils } from '../shared/utils';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
})
export class LogoutComponent implements OnInit {
  @HostBinding('class.signin') true;

  configData: object;

  constructor(private configService: ConfigService) { }

  ngOnInit() {
    this.loadConfig();
  }

  private loadConfig() {
    this.configService.getConfig('all').subscribe((res: Observable<any>) => {
      this.configData = res;
      this.removeOktaSession();
    }, (err) => {
      this.redirectToLoginPage();
    });
  }

  private removeOktaSession() {
    const url = this.configData['oktaUrl'] + Constants.OKTA_SESSION_URI;
    try {
      const xhr = new XMLHttpRequest();
      xhr.onerror = () => {
        console.log(`Invalid URL or Cross-Origin Request Blocked.
        You must explictly add this site ${window.location.origin} to the list of allowed websites in your Okta Admin Dashboard`);
        this.redirectToLoginPage();
      };
      xhr.onload = () => this.redirectToLoginPage();
      xhr.open('DELETE', url, false);
      xhr.setRequestHeader('Content-type', 'application/json');
      if ('withCredentials' in xhr) {
        xhr.withCredentials = true;
      }
      xhr.send();
    } catch (err) {
      this.redirectToLoginPage();
    }
  }

  private redirectToLoginPage() {
    const redirectUrl = Utils.getParam('redirect') || Constants.DEFAULT_LANDING_PAGE;
    window.location.href = decodeURIComponent(redirectUrl);
  }

}
