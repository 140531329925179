"use strict";
exports.__esModule = true;
// tslint:disable-next-line
var OktaSignIn = require("@okta/okta-signin-widget/dist/js/okta-sign-in-no-jquery");
var OktaService = /** @class */ (function () {
    function OktaService() {
    }
    OktaService.prototype.getWidget = function (oktaUrl, forgotPasswordlink) {
        this.widget = new OktaSignIn({
            baseUrl: oktaUrl,
            redirectUri: window.location.hostname,
            authParams: {
                issuer: 'default',
            },
            features: {
                rememberMe: false,
            },
            helpLinks: {
                forgotPassword: forgotPasswordlink,
            },
            i18n: {
                en: {
                    // tslint:disable
                    'enroll.choices.description': 'Your company requires multifactor authentication to add an additional layer of security when signing in to your Pitney Bowes account',
                },
            },
        });
        return this.widget;
    };
    return OktaService;
}());
exports.OktaService = OktaService;
