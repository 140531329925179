"use strict";
exports.__esModule = true;
var constants_1 = require("./constants");
// class introduced for QTWeb support
var LocalStorageWrapper = /** @class */ (function () {
    function LocalStorageWrapper(cookieService) {
        this.cookieService = cookieService;
    }
    LocalStorageWrapper.prototype.getItem = function (k) {
        try {
            if (typeof window.localStorage === 'undefined' || localStorage === null) {
                return this.cookieService.get(k);
            }
            else {
                return localStorage.getItem(k);
            }
        }
        catch (e) {
            // because localStorage returns string "null"
            return 'null';
        }
    };
    LocalStorageWrapper.prototype.setItem = function (k, v) {
        try {
            if (typeof window.localStorage === 'undefined' || localStorage === null) {
                this.cookieService.set(k, JSON.stringify(v));
            }
            else {
                localStorage.set(k, v);
            }
        }
        catch (e) {
            return;
        }
    };
    LocalStorageWrapper.prototype.removeItem = function (k) {
        try {
            if (typeof window.localStorage === 'undefined' || localStorage === null) {
                this.cookieService["delete"](k);
            }
            else {
                localStorage.removeItem(k);
            }
        }
        catch (e) {
            return;
        }
    };
    LocalStorageWrapper.prototype.clear = function () {
        try {
            this.removeItem(constants_1.Constants.USER_SELECTED_LOCALE);
            this.removeItem(constants_1.Constants.QUERY_PARAM_LOCALE);
            this.removeItem(constants_1.Constants.BROWSER_LOCALE);
        }
        catch (e) {
            return;
        }
    };
    return LocalStorageWrapper;
}());
exports.LocalStorageWrapper = LocalStorageWrapper;
