"use strict";
exports.__esModule = true;
require("rxjs/add/observable/throw");
require("rxjs/add/operator/catch");
var rxjs_1 = require("rxjs");
var uuid_1 = require("uuid");
var http_1 = require("@angular/common/http");
var app_error_1 = require("../error/app.error");
var utils_1 = require("../utils");
var profileBlob_service_1 = require("../services/profileBlob.service");
var constants_1 = require("../constants");
var HttpInterceptor = /** @class */ (function () {
    function HttpInterceptor(profileService) {
        var _this = this;
        this.profileService = profileService;
        this.handleError = function (error) {
            _this.onFinally();
            var message = 'Something went wrong!';
            if (error instanceof http_1.HttpErrorResponse) {
                switch (error.status) {
                    case 500:
                        message = 'Internal Server Error';
                        break;
                    case 403:
                        message = 'Authentication Failed';
                        break;
                    case 408:
                        message = 'Session Expired';
                        break;
                    case 401:
                        message = 'Unauthorized';
                        break;
                    case 400:
                        message = 'Bad Request';
                        break;
                    default:
                        message = "HTTP Error: " + error.status;
                        break;
                }
            }
            else if (error instanceof app_error_1.AppError) {
                message = error.message;
            }
            return rxjs_1.throwError(new app_error_1.AppError(_this, error, message));
        };
    } // Inject the ProfileService
    HttpInterceptor.prototype.intercept = function (req, next) {
        var _this = this;
        // Clone the request to add the new header.
        var authReq = req.clone({
            headers: new http_1.HttpHeaders({
                'Content-Type': 'application/json',
                'X-PB-Locale': utils_1.Utils.getCurrentLocale(),
                'Accept': 'application/json, text/plain, */*',
                'X-PB-TransactionId': this.getTransactionId(),
            }),
            withCredentials: true,
        });
        var profileBlob = this.profileService.profileBlob; // Get the profileBlob from ProfileService
        if (profileBlob) {
            // Add the profiling header here if profileBlob is available
            authReq = authReq.clone({
                setHeaders: {
                    'dwn-profiling': profileBlob,
                    "productId": utils_1.Utils.getParam(constants_1.Constants.PRODUCT_ID_PARAM)
                }
            });
        }
        document.body.style.cursor = 'wait';
        // send the newly created request
        return next.handle(authReq).map(function (response) {
            _this.onFinally();
            return response;
        })["catch"](this.handleError);
    };
    HttpInterceptor.prototype.onFinally = function () {
        document.body.style.cursor = 'default';
    };
    HttpInterceptor.prototype.getTransactionId = function () {
        var transactId = uuid_1.v4() + '-' + utils_1.Utils.getCurrentESTDateTimeInISOFormat();
        utils_1.Utils.setTransactionId(transactId);
        return transactId;
    };
    return HttpInterceptor;
}());
exports.HttpInterceptor = HttpInterceptor;
