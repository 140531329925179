import { CookieService } from 'ngx-cookie-service';

import { Constants } from './constants';

// class introduced for QTWeb support
export class LocalStorageWrapper {

    constructor(private cookieService: CookieService) { }

    getItem(k) {
        try {
            if (typeof window.localStorage === 'undefined' || localStorage === null) {
                return this.cookieService.get(k);
            } else {
                return localStorage.getItem(k);
            }
        } catch (e) {
            // because localStorage returns string "null"
            return 'null';
        }
    }
    setItem(k, v) {
        try {
            if (typeof window.localStorage === 'undefined' || localStorage === null) {
                this.cookieService.set(k, JSON.stringify(v));
            } else {
                localStorage.set(k, v);
            }
        } catch (e) {
            return;
        }
    }
    removeItem(k) {
        try {
            if (typeof window.localStorage === 'undefined' || localStorage === null) {
                this.cookieService.delete(k);
            } else {
                localStorage.removeItem(k);
            }
        } catch (e) {
            return;
        }
    }
    clear() {
        try {
            this.removeItem(Constants.USER_SELECTED_LOCALE);
            this.removeItem(Constants.QUERY_PARAM_LOCALE);
            this.removeItem(Constants.BROWSER_LOCALE);
        } catch (e) {
            return;
        }
    }
}
