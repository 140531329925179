import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';

import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs/Observable';
import { Constants } from '../constants';
import { Utils } from '../utils';

/**
 * @class ProductCatalogService service class to product catalog values
 * @see https://angular.io/api/core/Injectable
 */
@Injectable()
export class ProductCatalogService {

    /**
     * @constructor for ProductCatalogService
     * @param {HttpClient} http, to call product catalog service
     */
    constructor(private http: HttpClient) { }

    /**
     * @function getProductByQueryParam function to Call backend service to get product catalog from productId query param
     * @see https://angular.io/guide/observables
     * @see https://www.typescriptlang.org/docs/handbook/basic-types.html
     * @return Observable<any> | never
     */
    getProductByQueryParam(): Observable<any> | never {
        const productId = Utils.getParam('productId');
        if (productId === null) {
            throw new Error('Missing product ID');
        }
        const endpoint = Utils.getApiUrl() + Constants.LOGIN_SERVICES_API + Constants.API_VERSION +
            Constants.PRODUCT_DOMAIN + Constants.ROOT_URI + productId;
        return this.http
            .get(endpoint).map((response: HttpResponse<any>) => response);
    }

}
