"use strict";
exports.__esModule = true;
var router_1 = require("@angular/router");
var logout_component_1 = require("./logout/logout.component");
var constants_1 = require("./shared/constants");
var footer_component_1 = require("./shared/footer/footer.component");
var trust_guard_service_1 = require("./shared/services/trust-guard.service");
var splash_screen_component_1 = require("./shared/splash-screen/splash-screen.component");
/**
 * Define list of routes used in theLOGIN_ROUTE
 * @return {Routes} navigation route
 */
var LOGIN_ROUTE = '/' + constants_1.Constants.LOGIN_ROUTE + '?' + constants_1.Constants.PRODUCT_ID_PARAM + '=' + constants_1.Constants.APP_NAME
    + '&' + constants_1.Constants.TARGET_RESOURCE_PARAM + '=' + constants_1.Constants.DEFAULT_LANDING_PAGE;
var routes = [
    // not using constants for routes as compodoc is not supported with global variables
    { path: '', redirectTo: LOGIN_ROUTE, pathMatch: 'full' },
    { path: 'loading', component: splash_screen_component_1.SplashScreenComponent },
    { path: 'login', loadChildren: './login/login.module#LoginModule', canActivate: [trust_guard_service_1.TrustGuard] },
    { path: 'logout', component: logout_component_1.LogoutComponent, canActivate: [trust_guard_service_1.TrustGuard] },
    { path: 'forgot-password', loadChildren: './forgot-password/forgot-password.module#ForgotPasswordModule', canActivate: [trust_guard_service_1.TrustGuard] },
    { path: 'forgot-password/success', loadChildren: './forgot-password/success/forgot-password-success.module#ForgotPasswordSuccessModule' },
    { path: 'reset-password', loadChildren: './reset-password/reset-password.module#ResetPasswordModule', canActivate: [trust_guard_service_1.TrustGuard] },
    { path: 'reset-password/success', loadChildren: './reset-password/success/reset-password-success.module#ResetPasswordSuccessModule' },
    { path: 'claim-account', loadChildren: './claim-account/claim-account.module#ClaimAccountModule', canActivate: [trust_guard_service_1.TrustGuard] },
    { path: 'reclaim-account', loadChildren: './reclaim-account/reclaim-account.module#ReclaimAccountModule', canActivate: [trust_guard_service_1.TrustGuard] },
    { path: 'reclaim-account/success', loadChildren: './reclaim-account/success/reclaim-account-success.module#ReclaimAccountSuccessModule' },
    { path: 'error', loadChildren: './shared/error/error.module#ErrorModule' },
    { path: 'footer', component: footer_component_1.FooterComponent },
];
var AppRoutingModule = /** @class */ (function () {
    /**
     * @constructor for AppRoutingModule
     * @param {Router} router navigate to error page
     */
    function AppRoutingModule(router) {
        this.router = router;
    }
    return AppRoutingModule;
}());
exports.AppRoutingModule = AppRoutingModule;
