"use strict";
exports.__esModule = true;
require("rxjs/add/operator/catch");
require("rxjs/add/operator/map");
var rxjs_1 = require("rxjs");
var constants_1 = require("../constants");
var i0 = require("@angular/core");
/**
 * @class SplashScreenService service
 * @see https://angular.io/api/core/Injectable
 */
var SplashScreenService = /** @class */ (function () {
    function SplashScreenService() {
        this.dataLoaded = new rxjs_1.BehaviorSubject(false);
        this.loaderObserver$ = this.dataLoaded.asObservable();
        var pathname = window.location.pathname;
        if (pathname === constants_1.Constants.ROOT_URI + constants_1.Constants.RECLAIM_ACCOUNT_SUCCESS_ROUTE
            || pathname === constants_1.Constants.ROOT_URI + constants_1.Constants.RESET_PASSWORD_SUCCESS_ROUTE
            || pathname === constants_1.Constants.ROOT_URI + constants_1.Constants.LOGOUT_ROUTE) {
            this.dataLoadedHandler(true);
        }
    }
    SplashScreenService.prototype.dataLoadedHandler = function (loaded) {
        this.dataLoaded.next(loaded);
    };
    SplashScreenService.ngInjectableDef = i0.defineInjectable({ factory: function SplashScreenService_Factory() { return new SplashScreenService(); }, token: SplashScreenService, providedIn: "root" });
    return SplashScreenService;
}());
exports.SplashScreenService = SplashScreenService;
