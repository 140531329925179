import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/catch';
import { throwError } from 'rxjs';
import { Observable } from 'rxjs/Rx';
import { v4 as uuid } from 'uuid';

import {
    HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import { AppError } from '../error/app.error';
import { Utils } from '../utils';
import { ProfileService } from '../services/profileBlob.service';
import { Constants } from '../constants';

@Injectable()
export class HttpInterceptor implements HttpInterceptor {

    constructor(private profileService: ProfileService) { } // Inject the ProfileService

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        // Clone the request to add the new header.
        let authReq = req.clone({
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'X-PB-Locale': Utils.getCurrentLocale(),
                'Accept': 'application/json, text/plain, */*',
                'X-PB-TransactionId': this.getTransactionId(),
            }),
            withCredentials: true,
        });

        const profileBlob = this.profileService.profileBlob; // Get the profileBlob from ProfileService

        if (profileBlob) {
            // Add the profiling header here if profileBlob is available
            authReq = authReq.clone({
                setHeaders: {
                    'dwn-profiling': profileBlob,
                    "productId": Utils.getParam(Constants.PRODUCT_ID_PARAM) 
                }
            });
        }

        document.body.style.cursor = 'wait';
        // send the newly created request
        return next.handle(authReq).map((response) => {
            this.onFinally();
            return response;
        }).catch(this.handleError);
    }

    private onFinally(): void {
        document.body.style.cursor = 'default';
    }

    private getTransactionId(): string {
        const transactId = uuid() + '-' + Utils.getCurrentESTDateTimeInISOFormat();
        Utils.setTransactionId(transactId);
        return transactId;
    }

    private handleError = (error: any) => {
        this.onFinally();
        let message = 'Something went wrong!';
        
        if (error instanceof HttpErrorResponse) {
            switch (error.status) {
                case 500:
                    message = 'Internal Server Error';
                    break;
                case 403:
                    message = 'Authentication Failed';
                    break;
                case 408:
                    message = 'Session Expired';
                    break;
                case 401:
                    message = 'Unauthorized';
                    break;
                case 400:
                    message = 'Bad Request';
                    break;
                default:
                    message = `HTTP Error: ${error.status}`;
                    break;
            }
        } else if (error instanceof AppError) {
            message = error.message;
        }

        return throwError(new AppError(this, error, message));
    }
}
