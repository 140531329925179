"use strict";
exports.__esModule = true;
require("rxjs/add/operator/catch");
require("rxjs/add/operator/map");
var http_1 = require("@angular/common/http");
var router_1 = require("@angular/router");
var constants_1 = require("../shared/constants");
var utils_1 = require("../shared/utils");
/**
 * @class ValidateTokenService service class to validate token
 * @see https://angular.io/api/core/Injectable
 */
var ValidateTokenService = /** @class */ (function () {
    /**
     * @constructor for ValidateTokenService
     * @param {HttpClient} http, to call validate token service
     * @param {ActivatedRoute} activatedRoute to get query parameter (token) from URL
     */
    function ValidateTokenService(http, activatedRoute) {
        var _this = this;
        this.http = http;
        this.activatedRoute = activatedRoute;
        this.activatedRoute.queryParams.subscribe(function (params) {
            _this.token = params['token'];
        });
    }
    /**
     * @function validateToken function to call backend service to validate the token
     * @see https://angular.io/guide/observables
     * @return Observable<any>
     */
    ValidateTokenService.prototype.validateToken = function () {
        var endpoint = utils_1.Utils.getApiUrl() + constants_1.Constants.LOGIN_SERVICES_API + constants_1.Constants.API_VERSION +
            constants_1.Constants.VALIDATE_TOKEN_URI;
        return this.http
            .post(endpoint, { token: this.token }).map(function (response) { return response; });
    };
    return ValidateTokenService;
}());
exports.ValidateTokenService = ValidateTokenService;
