"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
exports.__esModule = true;
var i0 = require("./logout.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("./logout.component");
var i3 = require("../shared/services/config.service");
var styles_LogoutComponent = [i0.styles];
var RenderType_LogoutComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LogoutComponent, data: {} });
exports.RenderType_LogoutComponent = RenderType_LogoutComponent;
function View_LogoutComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "loading-screen"], ["style", "-webkit-box-orient: vertical;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "loading-screen-unit"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [["class", "loading-screen-logo"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [["class", "loading-screen-spinner text-white"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, ":svg:svg", [["height", "16"], ["width", "16"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, ":svg:path", [["d", "M8 16a7.928 7.928 0 0 1-3.428-.77l.857-1.807A6.006 6.006 0 0 0 14 8c0-3.309-2.691-6-6-6a6.006 6.006 0 0 0-5.422 8.572l-1.806.859A7.929 7.929 0 0 1 0 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z"], ["fill", "#fff"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Logging out... "]))], null, null); }
exports.View_LogoutComponent_0 = View_LogoutComponent_0;
function View_LogoutComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-logout", [], [[2, "signin", null]], null, null, View_LogoutComponent_0, RenderType_LogoutComponent)), i1.ɵdid(1, 114688, null, 0, i2.LogoutComponent, [i3.ConfigService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = true; _ck(_v, 0, 0, currVal_0); }); }
exports.View_LogoutComponent_Host_0 = View_LogoutComponent_Host_0;
var LogoutComponentNgFactory = i1.ɵccf("app-logout", i2.LogoutComponent, View_LogoutComponent_Host_0, {}, {}, []);
exports.LogoutComponentNgFactory = LogoutComponentNgFactory;
