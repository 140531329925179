"use strict";
exports.__esModule = true;
var AppError = /** @class */ (function () {
    function AppError(origin, e, message, alert) {
        if (alert === void 0) { alert = false; }
        this.origin = origin;
        this.details = this.error = e;
        this.message = '';
        this.errorCode = 'PB-ERR-001';
        /** Set Error message */
        if (e && e['message']) {
            this.message = e['message'];
        }
        /** Set Error details */
        if (e && e['error'] && e.error['errors'] && e.error.errors[0]) {
            this.details = e.error.errors;
        }
        else if (e && typeof e.json === 'function' && e.json().errors) {
            this.details = e.json().errors;
        }
        /** Set Error code */
        if (this.details[0] && this.details[0]['errorCode']) {
            this.errorCode = this.details[0].errorCode;
        }
        /** check, if any alert needed. Not implemented yet. */
        this.alert = alert ? alert : false;
    }
    return AppError;
}());
exports.AppError = AppError;
