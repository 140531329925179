"use strict";
exports.__esModule = true;
require("url-search-params-polyfill");
var core_1 = require("@angular/core");
var constants_1 = require("../shared/constants");
var config_service_1 = require("../shared/services/config.service");
var utils_1 = require("../shared/utils");
var LogoutComponent = /** @class */ (function () {
    function LogoutComponent(configService) {
        this.configService = configService;
    }
    LogoutComponent.prototype.ngOnInit = function () {
        this.loadConfig();
    };
    LogoutComponent.prototype.loadConfig = function () {
        var _this = this;
        this.configService.getConfig('all').subscribe(function (res) {
            _this.configData = res;
            _this.removeOktaSession();
        }, function (err) {
            _this.redirectToLoginPage();
        });
    };
    LogoutComponent.prototype.removeOktaSession = function () {
        var _this = this;
        var url = this.configData['oktaUrl'] + constants_1.Constants.OKTA_SESSION_URI;
        try {
            var xhr = new XMLHttpRequest();
            xhr.onerror = function () {
                console.log("Invalid URL or Cross-Origin Request Blocked.\n        You must explictly add this site " + window.location.origin + " to the list of allowed websites in your Okta Admin Dashboard");
                _this.redirectToLoginPage();
            };
            xhr.onload = function () { return _this.redirectToLoginPage(); };
            xhr.open('DELETE', url, false);
            xhr.setRequestHeader('Content-type', 'application/json');
            if ('withCredentials' in xhr) {
                xhr.withCredentials = true;
            }
            xhr.send();
        }
        catch (err) {
            this.redirectToLoginPage();
        }
    };
    LogoutComponent.prototype.redirectToLoginPage = function () {
        var redirectUrl = utils_1.Utils.getParam('redirect') || constants_1.Constants.DEFAULT_LANDING_PAGE;
        window.location.href = decodeURIComponent(redirectUrl);
    };
    return LogoutComponent;
}());
exports.LogoutComponent = LogoutComponent;
