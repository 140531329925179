"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
exports.__esModule = true;
var i0 = require("./app.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("./shared/footer/footer.component.ngfactory");
var i3 = require("./shared/footer/footer.component");
var i4 = require("./shared/splash-screen/splash-screen.component.ngfactory");
var i5 = require("./shared/splash-screen/splash-screen.component");
var i6 = require("./shared/splash-screen/splash-screen.service");
var i7 = require("@angular/router");
var i8 = require("@angular/common");
var i9 = require("./app.component");
var i10 = require("./shared/services/locale.service");
var i11 = require("./shared/services/trust-guard.service");
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
exports.RenderType_AppComponent = RenderType_AppComponent;
function View_AppComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-footer", [], [[2, "site-footer", null]], null, null, i2.View_FooterComponent_0, i2.RenderType_FooterComponent)), i1.ɵdid(1, 114688, null, 0, i3.FooterComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = true; _ck(_v, 0, 0, currVal_0); }); }
function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "site-root"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-splash-screen", [], null, null, null, i4.View_SplashScreenComponent_0, i4.RenderType_SplashScreenComponent)), i1.ɵdid(2, 114688, null, 0, i5.SplashScreenComponent, [i6.SplashScreenService], null, null), (_l()(), i1.ɵeld(3, 16777216, null, null, 1, "router-outlet", [["name", "header"]], null, null, null, null, null)), i1.ɵdid(4, 212992, null, 0, i7.RouterOutlet, [i7.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, "header"], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(5, 16777216, null, null, 1, "router-outlet", [["class", "site-content"]], null, null, null, null, null)), i1.ɵdid(6, 212992, null, 0, i7.RouterOutlet, [i7.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_1)), i1.ɵdid(8, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 2, 0); _ck(_v, 4, 0); _ck(_v, 6, 0); var currVal_0 = _co.dataLoaded; _ck(_v, 8, 0, currVal_0); }, null); }
exports.View_AppComponent_0 = View_AppComponent_0;
function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 4308992, null, 0, i9.AppComponent, [i1.ChangeDetectorRef, i10.LocaleService, i6.SplashScreenService, i11.TrustGuard], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_AppComponent_Host_0 = View_AppComponent_Host_0;
var AppComponentNgFactory = i1.ɵccf("app-root", i9.AppComponent, View_AppComponent_Host_0, {}, {}, []);
exports.AppComponentNgFactory = AppComponentNgFactory;
