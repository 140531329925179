"use strict";
exports.__esModule = true;
var core_1 = require("@angular/core");
var utils_1 = require("../utils");
var FooterComponent = /** @class */ (function () {
    function FooterComponent() {
        this.currentYear = (new Date()).getFullYear();
    }
    FooterComponent.prototype.ngOnInit = function () {
        // support for qtweb
        utils_1.Utils.setUnusualBrowserSettings();
    };
    return FooterComponent;
}());
exports.FooterComponent = FooterComponent;
