"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
exports.__esModule = true;
var styles = [".sr-only{position:absolute;overflow:hidden;width:1px;height:1px;padding:0;border:0;clip:rect(0,0,0,0);-webkit-clip-path:inset(50%);clip-path:inset(50%);white-space:nowrap}.loading-screen{position:fixed;z-index:10000;top:0;right:0;bottom:0;left:0;display:flex;flex-direction:column;align-items:center;justify-content:center}[data-theme=none] .loading-screen,[theme=none] .loading-screen{background-image:none!important}[data-theme=classic] .loading-screen,[theme=classic] .loading-screen{background-image:linear-gradient(135deg,#3e53a4 0,#cf0989 100%)!important}[data-theme=ocean] .loading-screen,[theme=ocean] .loading-screen{background-image:linear-gradient(135deg,#314183 0,#1f62ae 50%,#0c884c 100%)!important}[data-theme=sunset] .loading-screen,[theme=sunset] .loading-screen{background-image:linear-gradient(135deg,#78479f 0,#cf0989 50%,#cc4d14 100%)!important}[data-theme=twilight] .loading-screen,[theme=twilight] .loading-screen{background-image:linear-gradient(135deg,#0072b8 0,#314183 50%,#a03f9b 100%)!important}.loading-screen-logo{margin-bottom:10px}.loading-screen-spinner svg{-webkit-animation:2s linear infinite icon-spin;animation:2s linear infinite icon-spin}@-webkit-keyframes icon-spin{0%{-webkit-transform:rotate(0)}100%{-webkit-transform:rotate(360deg)}}@keyframes icon-spin{0%{transform:rotate(0)}100%{transform:rotate(360deg)}}"];
exports.styles = styles;
