"use strict";
exports.__esModule = true;
var http_1 = require("@angular/common/http");
var constants_1 = require("../constants");
var utils_1 = require("../utils");
var OriginValidatorService = /** @class */ (function () {
    /**
     * @constructor for OriginValidatorService
     * @param {HttpClient} http, to call origin validator service
     */
    function OriginValidatorService(http) {
        this.http = http;
    }
    /**
     * @function validateOrigin function to Call backend service to validate target resources
     * @see https://angular.io/guide/observables
     * @return Observable<any> | never
     */
    OriginValidatorService.prototype.validateOrigin = function (productId) {
        var targetResource = null;
        var logoutRedirect = false;
        if (window.location.pathname === constants_1.Constants.ROOT_URI + constants_1.Constants.LOGOUT_ROUTE) {
            if (!productId) {
                productId = 'login';
            }
            targetResource = utils_1.Utils.getParam('redirect') || constants_1.Constants.DEFAULT_LANDING_PAGE;
            logoutRedirect = true;
        }
        else {
            targetResource = utils_1.Utils.getParam(constants_1.Constants.TARGET_RESOURCE_PARAM) || utils_1.Utils.getParam(constants_1.Constants.FROM_URI_PARAM);
        }
        var endpoint = utils_1.Utils.getApiUrl() + constants_1.Constants.LOGIN_SERVICES_API + constants_1.Constants.API_VERSION +
            constants_1.Constants.ORIGIN_DOMAIN + constants_1.Constants.ROOT_URI;
        return this.http
            .post(endpoint, { targetResource: targetResource, logoutRedirect: logoutRedirect, productId: productId }).map(function (response) { return response; });
    };
    return OriginValidatorService;
}());
exports.OriginValidatorService = OriginValidatorService;
