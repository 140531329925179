"use strict";
exports.__esModule = true;
require("rxjs/add/operator/catch");
require("rxjs/add/operator/map");
var http_1 = require("@angular/common/http");
var constants_1 = require("../constants");
var utils_1 = require("../utils");
var i0 = require("@angular/core");
var i1 = require("@angular/common/http");
/**
 * @class ConfigService service class to get config values
 * @see https://angular.io/api/core/Injectable
 */
var ConfigService = /** @class */ (function () {
    /**
     * @constructor for ConfigService
     * @param {HttpClient} http, to call validate token service
     */
    function ConfigService(http) {
        this.http = http;
    }
    /**
     * @function validateToken function to call backend service to get Config params from API
     * @see https://angular.io/guide/observables
     * @return Observable<any>
     */
    ConfigService.prototype.refreshConfig = function (query) {
        var endpoint = utils_1.Utils.getApiUrl() + constants_1.Constants.LOGIN_SERVICES_API + constants_1.Constants.API_VERSION +
            constants_1.Constants.CONFIG_DOMAIN;
        return this.configData = this.http
            .get(endpoint, { params: { q: query } }).map(function (response) { return response; });
    };
    ConfigService.prototype.getConfig = function (query) {
        if (this.configData) {
            return this.configData;
        }
        return this.refreshConfig(query);
    };
    ConfigService.ngInjectableDef = i0.defineInjectable({ factory: function ConfigService_Factory() { return new ConfigService(i0.inject(i1.HttpClient)); }, token: ConfigService, providedIn: "root" });
    return ConfigService;
}());
exports.ConfigService = ConfigService;
