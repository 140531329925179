/**
 * @class Constants to hold application constants
 */
export class Constants {

    // app name
    static readonly APP_NAME = 'login';

    static readonly DEFAULT_LANDING_PAGE = 'https://www.pitneybowes.com';

    static readonly LOGIN_SERVICES_API = '/loginServices';

    static readonly API_VERSION = '/v2';

    static readonly ACCOUNT_DOMAIN = '/account';

    static readonly PRODUCT_DOMAIN = '/products';

    static readonly SESSION_DOMAIN = '/session';

    static readonly CONFIG_DOMAIN = '/config';

    static readonly ORIGIN_DOMAIN = '/origin/validate';

    static readonly ACCESS_TOKEN = 'x-access-token';

    static readonly REFRESH_TOKEN = 'x-refresh-token';

    // API URIs
    static readonly API_PROXY = '';

    static readonly ROOT_URI = '/';

    static readonly LOGIN_URI = '/login';

    static readonly LOGOUT_URI = '/logout';

    static readonly NO_ACCESS_URI = '/no_access';

    static readonly CALLBACK_URI = '/callback';

    static readonly FORGOT_PASSWORD_URI = '/forgotPassword';

    static readonly RESET_PASSWORD_URI = '/resetPassword';

    static readonly VALIDATE_TOKEN_URI = '/token/validate';

    static readonly CLAIM_ACCOUNT_URI = '/claim';

    static readonly RECLAIM_ACCOUNT_URI = '/reclaim';

    static readonly OKTA_USER_URI = '/api/v1/users/me';

    static readonly OKTA_SESSION_URI = '/api/v1/sessions/me';

    static readonly PRODUCT_CATALOG_URI = '';

    static readonly TRANSACTION_UUID_KEY = 'transactionUuid';

    static readonly X_PB_TRANSACTION_ID = 'X-PB-TransactionId';

    static readonly AUTHORIZATION = 'Authorization';

    static readonly PASSWORD_PATTERN = /(?=.*[A-Z])(?=.*[\d!@#$_%^&*-?~()])[A-Za-z\d!@#_$%^&*-?~()]{8,20}/;

    static readonly FORGIVE_ERRORS = ['/info'];

    static readonly DEFAULT_LOCALE = 'en-us';

    static readonly USER_SELECTED_LOCALE = 'user-selected-locale';

    static readonly QUERY_PARAM_LOCALE = 'query-param-locale';

    static readonly BROWSER_LOCALE = 'browser-locale';

    static readonly LOGIN_ROUTE = 'login';

    static readonly LOGOUT_ROUTE = 'logout';

    static readonly ERROR_ROUTE = 'error';

    static readonly FORGOT_PASSWORD_ROUTE = 'forgot-password';

    static readonly RECLAIM_ACCOUNT_ROUTE = 'reclaim-account';

    static readonly FORGOT_PASSWORD_SUCCESS_ROUTE = 'forgot-password/success';

    static readonly RECLAIM_ACCOUNT_SUCCESS_ROUTE = 'reclaim-account/success';

    static readonly LOADING_ROUTE = 'loading';

    static readonly RESET_PASSWORD_ROUTE = 'reset-password';

    static readonly RESET_PASSWORD_SUCCESS_ROUTE = 'reset-password/success';

    static readonly CLAIM_ACCOUNT_ROUTE = 'claim-account';

    static readonly TARGET_RESOURCE_PARAM = 'TargetResource';

    static readonly REDIRECT_PARAM = 'redirect';

    static readonly FROM_URI_PARAM = 'fromURI';

    static readonly TOKEN_PARAM = 'token';

    static readonly PRODUCT_ID_PARAM = 'productId';

    static readonly SPACE_STR = ' ';

      // MONGODB APIS

    static readonly MONGO_URI = "/mongoServices";

    static readonly CHECK_DOMAIN = "/checkDomain";
  
    static readonly CHECK_CONFIG = "/checkConfig"

}