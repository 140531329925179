"use strict";
exports.__esModule = true;
require("rxjs/add/operator/catch");
require("rxjs/add/operator/map");
var http_1 = require("@angular/common/http");
var constants_1 = require("../constants");
var utils_1 = require("../utils");
/**
 * @class ProductCatalogService service class to product catalog values
 * @see https://angular.io/api/core/Injectable
 */
var ProductCatalogService = /** @class */ (function () {
    /**
     * @constructor for ProductCatalogService
     * @param {HttpClient} http, to call product catalog service
     */
    function ProductCatalogService(http) {
        this.http = http;
    }
    /**
     * @function getProductByQueryParam function to Call backend service to get product catalog from productId query param
     * @see https://angular.io/guide/observables
     * @see https://www.typescriptlang.org/docs/handbook/basic-types.html
     * @return Observable<any> | never
     */
    ProductCatalogService.prototype.getProductByQueryParam = function () {
        var productId = utils_1.Utils.getParam('productId');
        if (productId === null) {
            throw new Error('Missing product ID');
        }
        var endpoint = utils_1.Utils.getApiUrl() + constants_1.Constants.LOGIN_SERVICES_API + constants_1.Constants.API_VERSION +
            constants_1.Constants.PRODUCT_DOMAIN + constants_1.Constants.ROOT_URI + productId;
        return this.http
            .get(endpoint).map(function (response) { return response; });
    };
    return ProductCatalogService;
}());
exports.ProductCatalogService = ProductCatalogService;
